import React from "react"
import "../style/index.css"
import Layout from "../components/layout"

const indexPage = () => {
  return (
    <Layout>
      <br /><br /><br /><br /> 
      <div id="carouselExampleIndicators" className="carousel slide z-9" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100" src="Images/Aspire Amber.jpg" alt="First slide" />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src="Images/Aurum_banner_sold_out.jpg" alt="Second slide" />
          </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>

      {/* ASPIRE ROW */}

      <div>
        <img src="Images/x.png" className="x-col" />
        <div className="bg-aspireblue">
          <div className="container div-aspire">
            <h2 className="pb-4 text-Montserrat-3">ASPIRE</h2>
            <div className="row text-center">
              <p className="mob-justify pl-5 pr-5 lh-2">Today’s aspirational Indian is in a hurry.
              Unlike their parents, they are not willing to wait till their forties to get their home.
              The Aspire Series from Sumadhura is the gateway for these ambitious young people to get the lifestyle of their dreams.
              Built on a foundation of real value without compromise, the Series offers compact, affordable apartments with premium
                   features and amenities at an unbelievable price tag.  </p>
            </div>
          </div>
        </div>
        <div className="aspire-red"></div>

        {/* MEET YOUR ASPIRATION */}
        <div className="bg-aspiregray pt-4">
          <div className="container">
            <h1 className="aspireblue text-center text-Montserrat-3">MEET YOUR ASPIRATIONS</h1>
            <div className="row text-center p-3">
              <div className="col-md-12"><img src="Images/1.png" /></div>
              <div className="col-md-12"><h4 className="h3-under pt-2 text-Montserrat">WINGS OF CHANGE</h4></div>
              <div className="col-md-12">
                <p className="mob-justify aspireblue lh-2">The Aspire Series was born out of Sumadhura's desire to give wings to young Indians'
                dreams of owning a home. An increasing number of young Indians are eschewing rentals and becoming homeowners on their own terms.
                And the one thing that unites all of them is that they are not willing to compromise on their terms.  The Aspire Series lives up
                to the changed expectations of the new home buyer who demands great quality and a host of amenities at a reasonable price. </p>
              </div>
            </div>

            <div className="row text-center p-3 mt-4">
              <div className="col-md-12"><img src="Images/2.png" /></div>
              <div className="col-md-12"><h4 className="h3-under pt-2 text-Montserrat">DELIVERED WITHOUT COMPROMISE</h4></div>
              <div className="col-md-12">
                <p className="mob-justify aspireblue tp lh-2">The Aspire series of homes has been designed to meet the aspiration and
                the non-compromise attitude of young India. The Aspire Series strives to deliver real value affordable housing,
                because when it comes to owning a home, nobody should compromise on </p>
              </div>
            </div>
            <div className="container">
              <div className="row text-center aspireblue li-fac">
                <div className="col-md-3">
                  <li className="fl"><i className="fa fa-map-marker pr-4 fs-5" aria-hidden="true"></i><span className="pl-2">Location</span></li>
                </div>
                <div className="col-md-3">
                  <li className="fl"><i className="fa fa-building pr-4 fs-5" aria-hidden="true"></i>Amenities</li>
                </div>
                <div className="col-md-3">
                  <li className="fl"><i className="fa fa-thumbs-up pr-4 fs-5" aria-hidden="true"></i>Quality of Construction</li>
                </div>
                <div className="col-md-3">
                  <li className="fl"><i className="fa fa-clock-o pr-4 fs-5" aria-hidden="true"></i>Timeline of delivery</li>
                </div>
              </div>
            </div>
            <div className="row text-center p-3">

            </div>

            <div className="row text-center p-3 pb-5 mt-4">
              <div className="col-md-12"><img src="Images/3.png" /></div>
              <div className="col-md-12"><h4 className="h3-under pt-2 text-Montserrat">BENEFITS BEYOND BRICKS AND MORTAR</h4></div>
              <div className="col-md-12">
                <p className="mob-justify aspireblue lh-2">Some of the most attractive benefits of most projects in the Aspire Series are not in the
                construction.  With all the advantages of 1% GST, Stamp Duty reduction and best interest
                  rates, the Aspire Series offers the best benefits in the market. </p>
              </div>

            </div>

          </div>
        </div>

      </div>

      {/* PROJECTS */}
      <div className="bg-aspireblue pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-5 text-center">
              <h2 className="text-white text-Montserrat-3">PROJECTS</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <div className="card border-0 bg-aspireblue">
                <a href="/aurum"><img className="card-img-top" src="Images/b1.png" alt="Card image cap" /></a>
                  <div className="">
                    <br />
	  	    <h1 className="text-white italia text-center">Aspire Aurum</h1>
                  </div>
	  	<a href="/aurum"><center><h5 className="card-detail">view more details</h5></center></a>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="aspire-red mob-div-sec"></div>
            <div className="col-md-4">
              <div className="card border-0 bg-aspireblue">
                <a href="/amber"><img className="card-img-top" src="Images/Amber_Listing.jpg" alt="Card image cap" /></a>
                <div className="">
                  <div className="">
                    <br />
	  	    <h1 className="text-white italia text-center">Aspire Amber</h1>
                  </div>
                </div>
	  	<a href="/amber"><center> <h5 className="card-detail">view more details</h5></center></a>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
      <div className="aspire-red"></div>

      {/* HEADER 2  */}
      <div className="container p-5 bg-aspireblue text-fam-mob">
        <div className="row">
          <div className="text-fam-mob">
            <h2 className="h2 text-Montserrat mob-fam text-white">BENEFITS OF </h2><h2 className="h2 text-Montserrat mob-fam text-white">AFFORDABLE HOUSING</h2>
            <p className="mt-4 lh-1 lh-2 text-justify text-white">Some of the most attractive benefits of the Aspire Series are not in the construction.
            The Aspire Series is eligible for the Pradhan Mantri Awas Yojana (PMAY) scheme.
            Factor in the 1% GST and the best interest rates in the market, and the reasons to decide on an Aspire home just get stronger.</p>
          </div>
        </div>
      </div>

      <div className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="row">
              <div className="sq-text">
                <div className="sq1">
                  <div className="sq2">
                    <div >
                      <h2 className="h2 text-Montserrat mob-fam">BENEFITS OF </h2><h2 className="h2 text-Montserrat mob-fam">AFFORDABLE HOUSING</h2>
                      <p className="mt-4 lh-1 lh-2 mob-fam">Some of the most attractive benefits of the Aspire Series are not in the construction. Factor in the 1% GST and the best interest rates in the market,
                         and the reasons to decide on an Aspire home just get stronger.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="d-block w-100" src="Images/h2.png" alt="First slide" />
          </div>
        </div>
      </div>
      <div className="aspire-red"></div>
      {/*NOT CUSTOMERS FANS  */}

      
      <div className="bg-aspiregray pt-5">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <h2 className="aspireblue pt-5 pb-5 text-Montserrat-3">NOT CUSTOMERS. FANS.</h2><br />
            </div>
          </div>

          <div className="container pb-5 ">
            <div className="row bg-aspireblue pl-5 pr-5 pt-5 pb-5">
              <div id="carouselExampleIndicators3" className="carousel slide z-9  w-100" data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators3" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators3" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators3" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner" >
                  <div className="carousel-item active w-100">
                    <div className="row">
                      <div className="col-md-4 img-center">
                        <img src="Images/c1.jpeg" className="img-w mb-3" style={{ width: "80%" }} />
                      </div>
                      <div className="col-md-8">
                      <p className="text-white mt-3 show-des">- Abhijit (Capability Team Lead) & Priyanka (Sr.Software Developer) - Accenture</p>
                        <p className=" text-justify lh-2 text-white mt-3"> We want to take a few minutes to appreciate the job that you are doing
                        in Customer Service space. You made it simple to book our first ever house with your project Aspire Aurum.
                        It has been so pleasing experience with you since the time we have booked the flat in terms any help needed especially
                        during the pandemic time. Its really great how the Aspire team keep on updating us on monthly basis about the progress
                        of the project. We are really happy and look forward to our new home.</p><br />
                        <p className="text-white hide-des">- Abhijit (Capability Team Lead) & Priyanka (Sr.Software Developer) - Accenture</p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item w-100">
                    <div className="row">
                      <div className="col-md-4 img-center">
                        <img src="Images/c2.jpeg" className="img-w mb-3" style={{ width: "80%" }} />
                      </div>
                      <div className="col-md-8">
                      <p className="text-white mt-3 show-des">- Goutam Maity (Sr. Software engineer) - GE Healthcare</p>
                        <p className=" text-justify lh-2 text-white mt-3">I observed that the construction work of Sumadhura Aspire Aurum project
                        going very well.  I would like to thank Sumadhura Aspire Aurum team and project workforce for the work that is going
                        on during Covid-19 pandemic situation. I certainly will have no hesitation on recommending Sumadhura Aspire
                      Aurum to any of my contacts.</p><br />
                        <p className="text-white hide-des">- Goutam Maity (Sr. Software engineer) - GE Healthcare</p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item w-100">
                    <div className="row">
                      <div className="col-md-4 img-center">
                        <img src="Images/c3.jpeg" className="img-w mb-3" style={{ width: "80%" }} />
                      </div>
                      <div className="col-md-8">
                      <p className="text-white mt-3 show-des">- Rathish KN (Sr manager) - Maruti Suzuki India ltd</p>
                        <p className=" text-justify lh-2 text-white mt-3">Aspire by Sumadhura is right investment for me in terms of ROI ,
                        as it's affordable housing with all the tax benefits and also the location advantage ,
                      I am confident this is one of my best Investments for midterm.</p><br />
                      <p className="text-white hide-des">- Rathish KN (Sr manager) - Maruti Suzuki India ltd</p>
                      </div>
                    </div>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators3" role="button" data-slide="prev">
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <span className="sr-only">Previous</span>
                </a>
                <img className="ap" src="../Images/ap.png"/>
                <a className="carousel-control-next" href="#carouselExampleIndicators3" role="button" data-slide="next">
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                 
                  <span className="sr-only">Next</span>
                </a>
                <img className="an" src="../Images/an.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
	  <script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
      {/* VIDEO SLIDER 

      <div className="container pt-5 pb-5">
        <div className="row">
          <div id="carouselExampleIndicators2" className="carousel slide z-9  w-100" data-ride="carousel">
            <ol className="carousel-indicators">
              <li data-target="#carouselExampleIndicators2" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
            </ol>
            <div className="carousel-inner" >
              <div className="carousel-item active">
                <div className="embed-responsive embed-responsive-16by9">
                <iframe width="auto" className="embed-responsive-item" src="https://www.youtube.com/embed/x_Xb8k7rOsc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
              </div>
              <div className="carousel-item">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe width="100%" className="embed-responsive-item" src="https://www.youtube.com/embed/vHymFOScroM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
              </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>*/}
    </Layout >
  )
}

export default indexPage
